import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { postHelpFormSubmission } from "../../redux/auth/query";
import { setUILoading } from "../../redux/ui/action";
import PrimaryButton from "../common/PrimaryButton";

export interface MembershipRequestFormProps {}

export function MembershipRequestForm() {
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [submissionStatus, setSubmissionStatus] = useState<Boolean>(false);
  const [submitStatus, setSubmitStatus] = useState<Boolean>(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setUILoading(true));

    try {
      setLoading(true);
      await postHelpFormSubmission({
        title: subject,
        message: message,
        contactPreference: "email",
      });

      setSubmissionStatus(true);
      setSubmitStatus(true);

      dispatch(setUILoading());
    } catch {
      setSubmissionStatus(false);
      setSubmitStatus(true);
      dispatch(setUILoading());
    } finally {
      setLoading(false);
    }
  };

  return (
    <VStack w="100%" align="flex-start" spacing={0}>
      <Text
        fontSize="20px"
        lineHeight="24px"
        fontWeight="semibold"
        fontFamily="montserrat"
        mb="12px"
      >
        Membership Request Form
      </Text>
      {submitStatus && submissionStatus && (
        <div style={{ color: "green" }}>
          Form has been successfully submitted! A BRT representative will reply
          within one business day.
        </div>
      )}
      {submitStatus && !submissionStatus && (
        <div style={{ color: "red" }}>
          There was an error when submitting the form. Please wait a few minutes
          and try again.
        </div>
      )}
      {!submitStatus && (
        <Stack spacing={0} w="100%">
          <Text fontSize="14px" lineHeight="19px" mb="24px">
            If you have a question about your company's membership information,
            please fill out the form below and a BRT representative will reply
            within one business day.
          </Text>

          <a href="https://forms.office.com/r/8g36WgEFtt" target="_blank">
            <Text
              fontSize="14px"
              color="primary.500"
              fontFamily="opensans"
              whiteSpace="nowrap"
              cursor={"pointer"}
              textDecoration={"underline"}
            >
              Membership Request Form
            </Text>
          </a>

          {/*<form onSubmit={handleSubmit}>*/}
          {/*  <FormControl id="title" isRequired mb="16px">*/}
          {/*    <FormLabel>Subject</FormLabel>*/}
          {/*    <Input*/}
          {/*      size="md"*/}
          {/*      w="100%"*/}
          {/*      variant="filled"*/}
          {/*      bg="white"*/}
          {/*      borderRadius="4px"*/}
          {/*      value={subject}*/}
          {/*      onChange={(e) => setSubject(e.target.value)}*/}
          {/*      border="1px solid"*/}
          {/*      borderColor="grey.80"*/}
          {/*    />*/}
          {/*  </FormControl>*/}
          {/*  <FormControl id="message" isRequired mb="40px">*/}
          {/*    <FormLabel>Message</FormLabel>*/}
          {/*    <Textarea*/}
          {/*      w="100%"*/}
          {/*      variant="filled"*/}
          {/*      bg="white"*/}
          {/*      borderRadius="4px"*/}
          {/*      h={{ base: "150px", md: "240px" }}*/}
          {/*      value={message}*/}
          {/*      onChange={(e) => setMessage(e.target.value)}*/}
          {/*      border="1px solid"*/}
          {/*      borderColor="grey.80"*/}
          {/*    />*/}
          {/*  </FormControl>*/}
          {/*  <PrimaryButton type="submit" isLoading={loading}>*/}
          {/*    Submit*/}
          {/*  </PrimaryButton>*/}
          {/*</form>*/}
        </Stack>
      )}
    </VStack>
  );
}
